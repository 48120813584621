import flatPickr from 'vue-flatpickr-component'
import {
    DxToolbar,
    DxItem,
    DxTexts,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
    DxLoadPanel,
    DxKeyboardNavigation
} from 'devextreme-vue/data-grid'
import PopupSelectTime from './PopupSelectTime.vue'
import dayjs from 'dayjs'
import { booking } from '@/api/booking'
import { starter } from '@/api/starter'
import { caddyCalendar } from '@/api/caddy-calendar'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import { courseSetting } from '@/api/course-setting'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import slideBarStarter from '@/views/modules/starter/slideBarDetail/slideBarStarter.vue'
import { getCssText } from '@/api/css-text'
import {
    F1,
    F2,
    F3,
    F4,
    F5,
    F6,
    F7,
    F8,
    F9,
    F10,
    F11,
    F12,
    A,
    F,
    S,
    Z,
    G
} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
import DxButton from 'devextreme-vue/button'
import 'devextreme/dist/css/dx.light.css'
import { commonServices } from '@/api/common-services'

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)

const dataGridRef = 'dataGrid'
export default {
    name: 'starter',
    components: {
        flatPickr,
        Treeselect,
        slideBarStarter,
        DxButton,
        DxTexts,
        DxDataGrid,
        DxLoadPanel,
        DxToolbar,
        DxItem,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxPaging,
        DxPager,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRangeRule,
        DxRequiredRule,
        DxValueFormat,
        DxColumnChooser,
        DxColumnFixing,
        DxSelection,
        DxExport,
        ShortKey,
        'confirm-dialog': ConfirmDialog,
        PopupSelectTime,
        DxKeyboardNavigation
    },
    data() {
        return {
            dataGridRef,
            currentLang: this.$i18n.locale,
            userData: JSON.parse(localStorage.getItem('userData')),
            listCourse: [],
            detailCaddy: {},
            selectedCourse: [],
            isShowGroup:true,
            isEdit: false,
            loading: true,
            slideBar_caddy: false,
            items: [],
            currentPage: 1,
            listBooking: [],
            perPage: 100,
            optionPerPage: [10, 50, 100, 200, 300],
            selectShow: ['ALL'],
            dataConfirm: {
                title: '',
                content: '',
            },
            isInto: true,
            dataBooking: null,
            configTable: [{
                    caption: this.$t('golf_boongking_booking_code'),
                    field: 'BookingCode',
                    isShow: true,
                    alignment: 'center',
                    width: '9%',
                    allowEditing: false,
                },
                {
                    caption: 'Group',
                    field: 'StarterGroupCode',
                    isShow: true,
                    alignment: 'center',
                    width: '9%',
                    allowEditing: false,
                },
                {
                    caption: 'Tee time',
                    field: 'TeeTime.OpenDateTime',
                    isShow: true,
                    alignment: 'center',
                    cellTemplate: 'tee-time',
                    width: '10%',
                    allowEditing: false,
                    allowFiltering: false,
                },
                {
                    caption: 'Time turn',
                    field: 'TimeTurn',
                    isShow: true,
                    alignment: 'center',
                    cellTemplate: 'time-turn',
                    width: '10%',
                    allowEditing: false,
                    allowFiltering: false,
                },
                {
                    caption: 'Checkout',
                    field: 'CheckOut',
                    isShow: true,
                    cellTemplate: 'check-out',
                    alignment: 'center',
                    width: '10%',
                    allowEditing: false,
                    allowFiltering: false,
                },
                {
                    caption: 'Tee',
                    field: 'TeeTime.TeeName',
                    isShow: true,
                    // cellTemplate: 'tee-name',
                    alignment: 'center',
                    width: '8%',
                    allowEditing: false,
                    allowFiltering: false,
                },
                {
                    caption: 'Locker',
                    field: 'LockerCode',
                    isShow: true,
                    alignment: 'center',
                    width: '10%',
                    // cellTemplate: 'bagtag-code',
                    allowEditing: false,
                },
                {
                    caption: 'Bagtag',
                    field: 'BagtagCode',
                    isShow: true,
                    alignment: 'center',
                    width: '10%',
                    // cellTemplate: 'bagtag-code',
                    allowEditing: false,
                },
                {
                    caption: 'BDC Code',
                    field: 'BDC',
                    isShow: true,
                    alignment: 'center',
                    width: '10%',
                    allowEditing: false,
                    cellTemplate: 'bdc-code',
                },
                // {
                //   caption: 'Locker',
                //   field: 'LockerCode',
                //   isShow: true,
                //   alignment: 'center',
                //   // cellTemplate: 'locker-code',
                //   width: '7%',
                //   allowEditing: false,
                // },
                {
                    caption: this.$t('golf_common_first_and_last_name'),
                    field: 'FullName',
                    isShow: true,
                    alignment: 'left',
                    cellTemplate: 'full-name',
                    width: '15%',
                    allowEditing: false,
                },
                {
                    caption: 'Golf class',
                    field: 'GolfClassName',
                    isShow: true,
                    alignment: 'center',
                    cellTemplate: 'golf-class',
                    width: '12%',
                    allowEditing: false,
                },
                {
                    caption: this.$t('golf_starter_caddy_name'),
                    field: 'ListCaddy',
                    isShow: true,
                    alignment: 'center',
                    width: '15%',
                    // editCellTemplate: 'edit-caddy',
                    allowEditing: false,
                },
                {
                    caption: this.$t('golf_starter_caddy_code'),
                    field: 'CaddyCode',
                    isShow: true,
                    alignment: 'center',
                    cellTemplate: 'caddy',
                    width: '15%',
                    allowEditing: true,
                },
                {
                    caption: this.$t('golf_starter_duplicate_caddy_code'),
                    field: 'IsCheckDuplicateCaddy',
                    isShow: true,
                    alignment: 'center',
                    cellTemplate: 'CheckDuplicateCaddy',
                    headerCellTemplate: 'HeaderCheckDuplicateCaddy',
                    width: '15%',
                    allowEditing: true,
                    allowFiltering: false,
                },
                {
                    caption: 'Buggy',
                    field: 'BuggyCode',
                    isShow: true,
                    alignment: 'center',
                    width: '10%',
                    allowEditing: true,
                    type: 'Number',
                    // editCellTemplate: 'edit-buggy-code',
                    // cellTemplate: 'buggy-code',
                },
                {
                    caption: this.$t('golf_common_hole'),
                    field: 'TotalNumberOfHole',
                    isShow: true,
                    alignment: 'center',
                    // cellTemplate: 'hole',
                    width: '5%',
                    allowEditing: false,
                    allowFiltering: false,
                },
                {
                    caption: '',
                    field: 'BookingDetailCourse',
                    isShow: true,
                    alignment: 'center',
                    cellTemplate: 'BookingDetailCourse',
                    width: '5%',
                    allowEditing: false,
                    allowFiltering: false,
                },
                {
                    caption: this.$t('golf_starter_tee_off'),
                    field: 'EntryTime',
                    isShow: true,
                    cellTemplate: 'entry-time',
                    alignment: 'center',
                    width: '5%',
                    allowEditing: true,
                    type: 'datetime',
                    editorOptions: {
                        displayFormat: 'HH:mm',
                        useMaskBehavior: false,
                        type: 'datetime',
                    },
                    allowFiltering: false,
                },
                {
                    caption: this.$t('golf_starter_finish'),
                    field: 'ExitTime',
                    isShow: true,
                    cellTemplate: 'exit-time',
                    alignment: 'center',
                    width: '5%',
                    allowEditing: true,
                    type: 'datetime',
                    editorOptions: {
                        displayFormat: 'HH:mm',
                        useMaskBehavior: false,
                        type: 'datetime',
                    },
                    allowFiltering: false,
                },
                {
                    caption: this.$t('golf_member_country'),
                    field: 'NationalityName',
                    isShow: true,
                    // cellTemplate: 'note',
                    alignment: 'left',
                    allowEditing: false,
                },
                {
                    caption: this.$t('golf_common_note'),
                    field: 'Note',
                    isShow: true,
                    // cellTemplate: 'note',
                    width: '150',
                    alignment: 'left',
                    allowEditing: false,
                },
                {
                    caption: this.$t('golf_common_note') + ' Starter',
                    field: 'NoteOfStarter',
                    isShow: true,
                    width: '150',
                    // cellTemplate: 'note',
                    alignment: 'left',
                    allowEditing: true,
                },
                {
                    caption: this.$t('golf_starter_syn_status'),
                    field: 'SgmsSynchronized',
                    isShow: true,
                    cellTemplate: 'syn_status',
                    alignment: 'center',
                    allowEditing: false,
                },
                {
                    caption: this.$t('golf_starter_syn_date'),
                    field: 'SgmsSynDate',
                    isShow: true,
                    cellTemplate: 'syn_date',
                    alignment: 'center',
                    allowEditing: false,
                },
                {
                    caption: this.$t('golf_starter_syn_ez_status'),
                    field: 'SgmsEZSynchronized',
                    isShow: true,
                    cellTemplate: 'syn_ez_status',
                    alignment: 'center',
                    allowEditing: false,
                },
                {
                    caption: this.$t('golf_starter_syn_ez_date'),
                    field: 'SgmsEZSynDate',
                    isShow: true,
                    cellTemplate: 'syn_ez_date',
                    alignment: 'center',
                    allowEditing: false,
                },
                {
                    caption: '',
                    field: 'action',
                    isShow: true,
                    cellTemplate: 'action',
                    alignment: 'center',
                    allowEditing: false,
                    width: '5%',
                },
            ],
            listFieldHide: [],
            isLoading: false,
            ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S, Z, G],
            allChildComponentClosed: [{
                ComponentId: 'sidebar-detail-starter',
                IsActive: false,
            }, ],
            listCaddy: [],
            currentFieldEdit: null,
            timeZone: localStorage.getItem('timeZone'),
            search: {
                KeyWord: null,
                OpenDate: this.getUTC('start', 'day'),
                ExpirationDate: this.getUTC('start', 'day'),
                BDC: [],
                CourseId: [],
                BagTag: [],
            },
            colorRow: {
                CheckIn: null,
                CheckOut: null,
                TeeOff: null,
                Finish: null,
            },
            allMode: 'allPages',
            checkBoxesMode: 'onClick', // 'always' :
            selectedRowsData: [],
            listGroupCode: [],
            selectedGroup: null,
            IsActiveMerge: (localStorage.getItem('isCreateGolferGroup') == '1'),
            DefaultSearch: null,
            dataGridInstance: null,
            isManualCheckDupCaddie: null,
            checkSelectCaddyInTable: null,
            listWorkingCaddyStatus: null,
            isShowPopupTime: null,
            PropsDataTeeOffFinish: null,
            IsAutoChangeCaddy: null,
            hileColum: false,
            windowHeight: 0,
            heightTable: 0,
            checkAllDupCaddy: false,
            ColorFillColumnBDC: null
        }
    },
    async created() {
        await this.getColorRowStarter()
        await this.getConfigShowGroup()
        await this.getListCaddyCalendar_RCAC01()
        await this.searchBy(this.DefaultSearch)
        await this.getListCourse()

        setTimeout(() => {
            if (this.DefaultSearch == 'BDC') {
                this.$refs.listBDC.$refs.search.focus()
            }
            if (this.DefaultSearch == 'BAGTAG') {
                this.$refs.listBagtag.$refs.search.focus()
            }
        }, 10)
    },
    mounted() {
        this.windowHeight = window.innerHeight
    },
    watch: {
        // windowHeight(value) {
        //   console.log(405, this.heightTable)
        //   if(this.heightTable == 0) {
        //     this.heightTable = value - 700
        //     console.log(value - 500)
        //   }
        // },
        'search.OpenDate': async function(newValue, _) {
            this.isLoading = true
                // await this.getListCaddyCalendar_RCAC01(true)
            await this.call_api_RBKSA1()
        },
    },
    computed: {
        configTableHide() {
            let list = []
            this.configTable.forEach(x => {
                if (x.field != 'StarterGroupCode' && x.field != 'action') {
                    list.push({
                        ...x,
                        id: x.field,
                        label: x.caption
                    })
                }
            })
            return list
        },
        // IsActiveMerge() {
        //   let result = false

        //   booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' }).then(res => {
        //     if (res.Status === '200') {
        //       if (res.Data.filter(x => x.KeyCode == 'STARTER_CREATE_GOLFER_GROUP').length && res.Data.find(x => x.KeyCode == 'STARTER_CREATE_GOLFER_GROUP').KeyValue == '1') {
        //         result = true
        //       }
        //     }
        //   })
        //   return result
        //   // setTimeout(() => result, 500)
        // },
        dataGrid() {
            return this.$refs[dataGridRef].instance
        },
    },
    methods: {
        async getConfigShowGroup() {
            debugger;
            await commonServices.getCommon({ KeyGroup: 'MODULE' }).then(res => {
                if (res.Status === '200') {
                    if (res.Data.filter(x => x.KeyCode == 'SGMS_BUGGY').length>0 && res.Data.filter(x => x.KeyCode == 'SGMS_BUGGY')[0].KeyValue == 'ON') {
                        this.isShowGroup = false
                    } else {
                        this.isShowGroup = true
                    }
                }
            })
        },
        HideColumn() {
            this.configTable.forEach(x => {
                this.listFieldHide.forEach(y => {
                    if (x.field === y) {
                        x.isShow = false
                    }
                })
            })
        },
        DisplayColumn() {
            this.configTable.forEach(x => {
                this.listFieldHide.forEach(y => {
                    if (x.field === y) {
                        x.isShow = true
                    }
                })
            })
        },
        getSelectedData() {
            this.selectedRowsData = this.dataGrid.getSelectedRowsData()
        },
        async createGroup() {
            this.getSelectedData()
            if (this.selectedRowsData.length) {
                const BookingDetailId = this.selectedRowsData.map(x => x.Id)
                const body = {
                    BookingDetailId,
                    StarterGroupCode: null,
                    IsUpdate: false,
                }
                await starter
                    .api_IBKSA1(body)
                    .then(res => {
                        this.showResToast(res)
                        if (res.Status == '200') {
                            this.searchBy(this.DefaultSearch)
                        }
                    })
            } else {
                this.showToast('error', 'golf_starter_please_select_member_to_create_group')
            }
        },
        // Lấy color row của bảng starter
        async getColorRowStarter() {
            await commonServices
                .getCommon({ KeyGroup: 'BOOKING_CONFIG' })
                .then(res => {
                    this.listWorkingCaddyStatus = res ?.Data.find(x => x.KeyCode === 'BOOKING_CADDY_STATUS_ALLOW_BOOKING').KeyValue
                    this.DefaultSearch = res ?.Data.find(x => x.KeyCode === 'BOOKING_DEFAULT_SEARCH').KeyValue
                    this.colorRow.CheckIn = res ?.Data ?.find(x => x.KeyCode === 'COLOR_BOOKING_CHECKIN').KeyValue
                    this.isShowPopupTime = res ?.Data ?.find(x => x.KeyCode === 'STARTER_SHOW_POPUP_TEEOFF_FINISH_TIME').KeyValue
                    this.colorRow.CheckOut = res ?.Data ?.find(x => x.KeyCode === 'COLOR_BOOKING_CHECKOUT').KeyValue
                    this.colorRow.TeeOff = res ?.Data ?.find(x => x.KeyCode === 'COLOR_BOOKING_TEEOFF').KeyValue
                    this.colorRow.Finish = res ?.Data ?.find(x => x.KeyCode === 'COLOR_BOOKING_FINISH').KeyValue
                    this.heightTable = Number(res ?.Data ?.find(x => x.KeyCode === 'STARTER_HEIGHT_OF_TABLE_LIST_BY_PIXEL').KeyValue)
                    this.IsAutoChangeCaddy = res ?.Data.find(x => x.KeyCode === 'BOOKING_AUTO_UPDATE_HOLE_OF_CADDIE').KeyValue
                    this.listFieldHide = JSON.parse(res ?.Data ?.find(x => x.KeyCode === 'STARTER_HIDE_COLUMN').KeyValue)
                    this.isManualCheckDupCaddie = res ?.Data.find(x => x.KeyCode === 'BOOKING_MANUAL_CHECK_DUP_CADDIE').KeyValue
                    this.checkSelectCaddyInTable = res ?.Data.find(x => x.KeyCode === 'STARTER_ALLOW_SELECT_CADDY_FROM_DROPDOWN_LIST').KeyValue
                    this.ColorFillColumnBDC = res ?.Data.find(x => x.KeyCode === 'STARTER_FILL_COLOR_BY_BDC_COLUMN').KeyValue
                    this.HideColumn()

                    if (this.heightTable == 0) {
                        this.heightTable = this.windowHeight - 250
                    }
                    this.$forceUpdate()
                })
        },
        async getListCaddyCalendar_RCAC01(isChange) {
            if (isChange) {
                this.search.ExpirationDate = this.search.OpenDate
            }
            this.search.CaddyWorkingStatus = this.listWorkingCaddyStatus.split(',')
            this.listCaddy = []
                // Lấy danh sách tổ caddy
            await caddyCalendar
                .getListCaddyCalendar(this.search)
                .then(res => {
                    if (res.Status == '200') {
                        res.Data.CaddyCalendar.forEach(x => {
                            x.Shift.forEach(y => {
                                y.Caddy.forEach(z => {
                                    if (!this.listCaddy.find(item => item.Id === z.Id)) {
                                        this.listCaddy.push(z)
                                    }
                                })
                            })
                        })
                    }
                })
        },
        _dayjs: dayjs,
        cellChange(data) {
            // console.log('old', this.oldData)
            // console.log('save', data.changes[0].data)
            if (this.currentFieldEdit == 'BuggyCode') {
                const body = {
                    BookingDetail: {
                        Id: data.changes[0].data.Id,
                        BuggyCode: data.changes[0].data.BuggyCode,
                    },
                    RetailBy: 'STARTER',
                }
                booking
                    .api_UBK10(body)
                    .then(res => {
                        this.showResToast(res)
                        this.searchBy(this.DefaultSearch)
                    })
            }

            if (this.currentFieldEdit == 'EntryTime') {
                const time = this.dayjs(data.changes[0].data.EntryTime).tz(this.timeZone).format()
                const utctime = this.dayjs(time).utc().format()

                const body = {
                    BookingDetailId: data.changes[0].data.Id,
                    Type: 'IN',
                    Time: utctime,
                }
                starter.api_UCI01(body).then(response => {
                    this.showResToast(response)
                    this.searchBy(this.DefaultSearch)
                })
            }

            if (this.currentFieldEdit == 'ExitTime') {
                const time = this.dayjs(data.changes[0].data.ExitTime).tz(this.timeZone).format()
                const utctime = this.dayjs(time).utc().format()

                const body = {
                    BookingDetailId: data.changes[0].data.Id,
                    Type: 'OUT',
                    Time: utctime,
                }
                starter.api_UCI01(body).then(response => {
                    this.showResToast(response)
                    this.searchBy(this.DefaultSearch)
                })
            }

            if (this.currentFieldEdit == 'CaddyId') {
                const payload = {
                    BookingDetailCaddy: {
                        Id: null,
                        BookingDetailId: data.changes[0].data.Id,
                        CaddyId: Number(data.changes[0].data.CaddyId),
                        NumberOfHole: data.changes[0].data.TotalNumberOfHole,
                        WorkingStatus: 'B',
                        Reasons: '',
                        IsDeleted: false,
                        IsBooked: true,
                        BookingDetailServices: [{
                            BookingDetailId: data.changes[0].data.Id,
                            ServiceId: 0,
                            UnitPrice: 0,
                            Quantity: 0,
                            Amount: 0,
                            TotalAmount: 0,
                            TransactionCode: '',
                        }, ],
                    },
                }
                booking.updateCaddy(payload).then(response => {
                    this.showResToast(response)
                    this.searchBy(this.DefaultSearch)
                })
            }
        },
        cellClick(data) {
            if (data.column.dataField == 'TeeTime.TeeName' || data.column.dataField == 'TeeTime.OpenDateTime' || data.column.dataField == 'NumberOfHole') {
                // Gán data để mở modal chọn số hố
                if (data.rowType != "header") {
                    this.dataBooking = data.data
                    this.$root.$emit('bv::show::modal', 'modal-line-course')
                }
            }

            // else if (!data.row.isEditing && data.column.dataField) {
            //   // Nếu không phải đang sửa row thì click vào ô nào cũng là xem chi tiết
            //   this.call_api_RBKSA2(data.data)
            // }
        },
        async searchBy(value) {
            await this.call_api_RBKSA1()
            if (value == 'BDC') {
                this.$refs.listBDC.$refs.search.focus()
            }
            if (value == 'BAGTAG') {
                this.$refs.listBagtag.$refs.search.focus()
            }
        },
        async rowUpdating(data) {
            let error = true
            if (data.newData.BuggyCode || data.newData.BuggyCode == '' || data.newData.NoteOfStarter || data.newData.NoteOfStarter == '') {
                const body = {
                    BookingDetail: {
                        Id: data.oldData.Id,
                        NoteOfStarter: data.newData.NoteOfStarter || data.newData.NoteOfStarter == '' ? data.newData.NoteOfStarter : data.oldData.NoteOfStarter,
                        BuggyCode: data.newData.BuggyCode || data.newData.BuggyCode == '' ? data.newData.BuggyCode : data.oldData.BuggyCode,
                    },
                    RetailBy: 'STARTER',
                }
                await booking
                    .api_UBK10(body)
                    .then(res => {
                        if (res.Status == '200') {
                            error = false
                                // this.call_api_RBKSA1()
                        } else {
                            this.showResToast(res)
                        }
                    })
            }
            if (data.newData.EntryTime) {
                const time = this.dayjs(data.newData.EntryTime).tz(this.timeZone).format()
                const utctime = this.dayjs(time).utc().format()

                const body = {
                    BookingDetailId: data.oldData.Id,
                    Type: 'IN',
                    Time: utctime,
                }
                await starter.api_UCI01(body).then(response => {
                    if (response.Status == '200') {
                        error = false
                            // this.call_api_RBKSA1()
                    } else {
                        this.showResToast(response)
                    }
                })
            }
            
            if (data.newData.EntryTime === null) {
                const time = this.dayjs('1900-01-01').tz(this.timeZone).format()
                const utctime = this.dayjs(time).utc().format()
                const body = {
                    BookingDetailId: data.oldData.Id,
                    Type: 'IN_CLEAR',
                    Time: utctime,
                }
                await starter.api_UCI01(body).then(response => {
                    if (response.Status == '200') {
                        error = false
                            // this.call_api_RBKSA1()
                    } else {
                        this.showResToast(response)
                    }
                })
            }
            if (data.newData.CaddyCode) {
                const dataCaddy = this.listCaddy.find(x => x.CaddyCode.toUpperCase() == data.newData.CaddyCode.toUpperCase())
                if (dataCaddy) {
                    const payload = {
                        BookingDetailCaddy: {
                            Id: null,
                            BookingDetailId: data.oldData.Id,
                            CaddyId: Number(dataCaddy.Id),
                            NumberOfHole: data.oldData.TotalNumberOfHole,
                            WorkingStatus: 'B',
                            Reasons: '',
                            IsDeleted: false,
                            IsBooked: false,
                            BookingDetailServices: [{
                                BookingDetailId: data.oldData.Id,
                                ServiceId: 0,
                                UnitPrice: 0,
                                Quantity: 0,
                                Amount: 0,
                                TotalAmount: 0,
                                TransactionCode: '',
                            }, ],
                        },
                        IsForceUpdateCaddie: data.newData.IsCheckDuplicateCaddy == 1 ? true : false
                    }
                    await booking.updateCaddy(payload).then(async response => {
                        if (response.Status == '200') {
                            error = false
                            this.call_api_RBKSA1()
                        } else {
                            this.showResToast(response)
                        }
                    })
                } else {
                    this.showToast('error', this.$t('golf_starter_caddy_no_calendar'))
                }
            }
            if (data.newData.ExitTime === null) {
                const time = this.dayjs('1900-01-01').tz(this.timeZone).format()
                const utctime = this.dayjs(time).utc().format()

                const body = {
                    BookingDetailId: data.oldData.Id,
                    Type: 'OUT_CLEAR',
                    Time: utctime,
                }
                await starter.api_UCI01(body).then(response => {
                    if (response.Status == '200') {
                        error = false
                            // this.call_api_RBKSA1()
                    } else {
                        this.showResToast(response)
                    }
                })
            }
            if (data.newData.ExitTime) {
                const time = this.dayjs(data.newData.ExitTime).tz(this.timeZone).format()
                const utctime = this.dayjs(time).utc().format()

                const body = {
                    BookingDetailId: data.oldData.Id,
                    Type: 'OUT',
                    Time: utctime,
                }
                await starter.api_UCI01(body).then(response => {
                    if (response.Status == '200') {
                        error = false
                            // this.call_api_RBKSA1()
                    } else {
                        this.showResToast(response)
                    }
                })
            }
            this.searchBy(this.DefaultSearch)
            if (!error) {
                this.showToast('success', this.$t('golf_common_success'))
            }
        },
        async getListGroupCode_RBKSA3() {
            await starter
                .api_RBKSA3({ Date: this.search.OpenDate })
                .then(res => {
                    if (res) {
                        this.listGroupCode = res.Data.StarterGroup
                    }
                })
        },
        async updateTeeOffFinishStarter_UCI01(check, BookingDetailId) {
            const time = this.dayjs().tz(this.timeZone).format()
            const utctime = this.dayjs(time).utc().add(-1, 'minutes').format()
            const TeeOff = {
                BookingDetailId: BookingDetailId,
                Type: 'IN',
                Time: utctime,
            }
            const Finish = {
                BookingDetailId: BookingDetailId,
                Type: 'OUT',
                Time: utctime,
            }
            await starter.api_UCI01(check ? TeeOff : Finish).then(response => {
                if (response.Status === '200' && response.Messages.length > 0) {
                    this.showResToast(response)
                }
                if (response.Status != '200') {
                    this.showResToast(response)
                }
            })
        },
        async call_api_RBKSA1() {
            this.search.ExpirationDate = `${this.dayjs(this.search.OpenDate)
        .utc().tz(localStorage.getItem('timeZone'))
        .add(-(localStorage.getItem('timeZoneOffset') / 60), 'hour')
        .add(1, 'day')
        .format('YYYY-MM-DDTHH:mm:ss')}Z`
            this.search.CourseId = this.selectedCourse
            this.isLoading = true
            this.checkAllDupCaddy = false
            await starter
                .api_RBKSA1(this.search)
                .then(res => {
                    if (res) {
                        res.Data.BookingDetail.forEach(x => {
                            x.IsCheckDuplicateCaddy = null
                        })
                        this.listBooking = [...res.Data.BookingDetail]
                            // Lấy danh sách group code
                        this.getListGroupCode_RBKSA3()
                    }
                })
            this.isLoading = false
        },
        getListCourse() {
            // Lấy danh sách sân
            courseSetting
                .getListCourse()
                .then(res => {
                    if (res) {
                        this.listCourse = res.Data.Course.map(item => ({
                            ...item,
                            id: item.Id,
                            label: item.CourseName,
                        }))
                        this.selectedCourse = this.listCourse.map(x => x.id)
                    }
                })
        },
        actionOnSourse(data) {
            if (!data.EntryTime) {
                this.dataConfirm = {
                    title: this.$t('golf_starter_confirm_teeoff'),
                    content: this.$t('golf_starter_confirm_teeoff_member'),
                }
                this.isInto = true
            } else if (!data.ExitTime) {
                this.dataConfirm = {
                    title: this.$t('golf_starter_confirm_finish'),
                    content: this.$t('golf_starter_confirm_finish_member'),
                }
                this.isInto = false
            }
            this.$refs.confirmDialogComponent.open({
                Id: data.Id,
                IsDetail: data.IsDetail,
                dataConfirm: this.dataConfirm,
            })
        },
        async actionChangeHoleAll() {
            this.getSelectedData()
            if (this.selectedRowsData.length) {
                this.dataBooking = JSON.parse(JSON.stringify(this.selectedRowsData[0]))
                this.dataBooking.type = 'UpdateHoleAll'
                this.$root.$emit('bv::show::modal', 'modal-line-course')
            } else {
                this.showToast('error', this.$t('golf_starter_please_select_member'))
            }
        },
        async actionOnSourseAll(checkIsInto) {
            this.getSelectedData()
            if (this.selectedRowsData.length) {
                if (this.isShowPopupTime == 1) {
                    if (checkIsInto) {
                        this.PropsDataTeeOffFinish = 'TeeOff'
                    } else {
                        this.PropsDataTeeOffFinish = 'Finish'
                    }
                    this.$bvModal.show('modal-select-time')
                } else {
                    this.isLoading = true
                    for (let i = 0; i < this.selectedRowsData.length; i++) {
                        await this.updateTeeOffFinishStarter_UCI01(checkIsInto, this.selectedRowsData[i].Id)
                    }
                    await this.searchBy(this.DefaultSearch)
                    this.isLoading = false
                }
            } else {
                this.showToast('error', this.$t('golf_starter_please_select_member'))
            }
        },
        async handleEvent(data) {
            if (data.type == 'after-select-time-teeoff') {
                this.isLoading = true
                for (let i = 0; i < this.selectedRowsData.length; i++) {
                    const body = {
                        BookingDetailId: this.selectedRowsData[i].Id,
                        Type: 'IN',
                        Time: data.time,
                    }
                    await starter.api_UCI01(body).then(response => {
                        if (response.Status === '200' && response.Messages.length > 0) {
                            this.showResToast(response)
                        }
                        if (response.Status != '200') {
                            this.showResToast(response)
                        }
                    })
                }
                await this.searchBy(this.DefaultSearch)
                this.isLoading = false
            }
            if (data.type == 'after-select-time-finish') {
                this.isLoading = true
                for (let i = 0; i < this.selectedRowsData.length; i++) {
                    const body = {
                        BookingDetailId: this.selectedRowsData[i].Id,
                        Type: 'OUT',
                        Time: data.time,
                    }
                    await starter.api_UCI01(body).then(response => {
                        if (response.Status === '200' && response.Messages.length > 0) {
                            this.showResToast(response)
                        }
                        if (response.Status != '200') {
                            this.showResToast(response)
                        }
                    })
                }
                await this.searchBy(this.DefaultSearch)
                this.isLoading = false
            }
            if (data.type == 'after-change_all_hole') {
                this.isLoading = true
                for (let i = 0; i < this.selectedRowsData.length; i++) {
                    const body = {
                        BookingDetail: {
                            Id: this.selectedRowsData[i].Id,
                            Line: data.data.BookingDetail.Line,
                            BookingDetailCourse: data.data.BookingDetail.BookingDetailCourse,
                        },
                        RateAdditionalType: data.data.RateAdditionalType,
                        IsLock: false
                    }
                    await starter
                        .api_UBK02B(body)
                        .then(async res => {
                            if (res) {
                                if (res.Status === '200') {
                                    if (this.selectedRowsData[i].BookingDetailCaddy.length === 1) {
                                        if (this.IsAutoChangeCaddy == 0) {
                                            let 
                                                payload = {
                                                    BookingDetailCaddy: {
                                                        Id: this.selectedRowsData[i].BookingDetailCaddy[0].Id,
                                                        BookingDetailId: this.selectedRowsData[i].Id,
                                                        CaddyId: Number(this.selectedRowsData[i].BookingDetailCaddy[0].CaddyId),
                                                        NumberOfHole: data.data.BookingDetail.Line.length * 9,
                                                        WorkingStatus: this.selectedRowsData[i].BookingDetailCaddy[0].WorkingStatus,
                                                        Reasons: this.selectedRowsData[i].BookingDetailCaddy[0].Reasons,
                                                        IsDeleted: this.selectedRowsData[i].BookingDetailCaddy[0].IsDeleted,
                                                        BookingDetailServices: [],
                                                    },
                                            }

                                            await booking.updateCaddy(payload).then(res => {
                                                if (res.Status != 200) {
                                                    this.showResToast(res)
                                                }
                                            })
                                        }
                                    } else if (this.selectedRowsData[i].BookingDetailCaddy.length > 1) {
                                        this.showToast('error', `BDC: ${this.selectedRowsData[i].BDC} có ${this.selectedRowsData[i].BookingDetailCaddy.length} caddy phục vụ. Vui lòng cập nhật số hố caddy`)
                                    }
                                } else {
                                    this.showResToast(res)
                                }
                            }
                        })
                }
                await this.searchBy(this.DefaultSearch)
                this.isLoading = false
            }
        },
        call_api_RBKSA2(val) {
            this.dataBooking = val
            this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-starter')
                // const body = {
                //   BookingDetail: {
                //     Id : id,
                //   },
                // }
                // starter
                //   .api_RBKSA2(body)
                //   .then(res => {
                //     // if (res) {
                //     //   this.call_api_RBKSA1()
                //     // }
                //   })
        },
        async call_api_UBKSA(data) {
            await this.updateTeeOffFinishStarter_UCI01(this.isInto, data.Id)
            if (data.IsDetail) {
                this.$refs.SidebarStarter.call_api_RBKSA2()
            }
            await this.searchBy(this.DefaultSearch)
                // const bodyCi = {
                //   BookingDetail: [
                //     {
                //       Id: data.Id,
                //       CheckIn: {},
                //     },
                //   ],
                //   Type: 'IN',
                // }
                // const bodyCo = {
                //   BookingDetailId: [data.Id],
                //   Type: 'OUT',
                // }
                // starter
                //   .api_UBKSA(this.isInto ? bodyCi : bodyCo, this.isInto)
                //   .then(res => {
                //     this.showResToast(res)
                //     if (res) {
                //       
                //     }
                //   })
        },
        call_api_UBKSA_All(data) {
            const listBookingDetail = []
            data.forEach(x => {
                listBookingDetail.push({
                    Id: x.Id,
                    CheckIn: {},
                })
            })
            const bodyCi = {
                BookingDetail: listBookingDetail,
                Type: 'IN',
            }
            const bodyCo = {
                BookingDetailId: data.map(x => x.Id),
                Type: 'OUT',
            }
            starter
                .api_UBKSA(this.isInto ? bodyCi : bodyCo, this.isInto)
                .then(res => {
                    this.showResToast(res)
                    if (res) {
                        this.$refs.SidebarStarter.call_api_RBKSA2()
                        this.searchBy(this.DefaultSearch)
                    }
                })
        },
        onRowSelected(item) {
            if (item.column.name != 'action' && item.data) {
                this.call_api_RBKSA2(item.data)
            }

            // this.call_api_RBKSA2(item[0])
        },
        openModalUpdateGroup() {
            this.getSelectedData()
            if (this.selectedRowsData.length) {
                this.$root.$emit('bv::show::modal', 'modal-starter-update-group')
            } else {
                this.showToast('error', 'golf_starter_please_select_member_to_update')
            }
        },
        async updateGroup() {
            const BookingDetailId = this.selectedRowsData.map(x => x.Id)
            const body = {
                BookingDetailId,
                StarterGroupCode: (this.selectedGroup) ? this.selectedGroup.StarterGroupCode : null,
                IsUpdate: true,
            }
            await starter
                .api_IBKSA1(body)
                .then(res => {
                    this.showResToast(res)
                    if (res.Status == '200') {
                        this.searchBy(this.DefaultSearch)
                        this.selectedGroup = null
                    }
                })
        },
        printStarter() {
            const prtHtml = document.getElementById('starter-print').innerHTML

            let stylesHtml = `<style type="text/css">.ez-icon {display: none} ${getCssText()}</style>`
            for (const node of[...document.querySelectorAll('link')]) {
                stylesHtml += node.outerHTML
            }

            // Open the print window
            const WinPrint = window.open('', '', 'left=0,top=0,height=900,toolbar=0,scrollbars=0,status=0')

            WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          <div style="text-align: center; margin: 10px 0">
            <h2>Điều phối khách</h2>
            <i>Thời gian in: ${this.utcConvertToLocalTimeZone(new Date(), 'DD/MM/YYYY HH:mm:ss')}</i>
          </div>
          ${prtHtml}
          <style>@media print { @page { margin:0; } *{font-family:  Arial, Helvetica, sans-serif ;} .table-responsive {overflow-x: hidden;}}</style>
          <script> window.onload = function (e){ window.print(); }</script>
        </body>
      </html>`)

            WinPrint.document.close()
        },
        async getFullBDCShortCutKey(BDCIndex) {
            const body = {
                KeyWord: this.search.KeyWord,
                OpenDate: this.search.OpenDate,
                CourseId: this.selectedCourse,
                BDC: [BDCIndex],
            }
            await starter
                .api_RBKSA1(body)
                .then(res => {
                    if (res.Data.BookingDetail.length > 0) {
                        this.call_api_RBKSA2(res.Data.BookingDetail[0])
                    } else {
                        this.showToast('error', this.$t('golf_starter_error_no_find_bdc').replace('{BDCIndex}', BDCIndex))
                    }
                    // this.call_api_RBKSA2(res.Data.BookingDetail[0])
                })
        },
        SidebarOpenOrCloseHandler(val) {
            this.allChildComponentClosed.find(x => {
                if (x.ComponentId === val.ComponentId) {
                    x.IsActive = val.IsActive
                }
            })
        },
        async reSynSgms(data) {
            const body = {
                MessageId: data
            }
            await starter.api_BG_UBK01S(body).then(res => {
                this.showResToast(res)
                if (res.Status == '200') {
                    this.searchBy(this.DefaultSearch)
                }
            })
        },
        async SynSgms(data) {
            const body = {
                MessageId: null,
                BookingDetailId: data
            }
            await starter.api_BG_UBK01S(body).then(res => {
                this.showResToast(res)
                if (res.Status == '200') {
                    this.searchBy(this.DefaultSearch)
                }
            })
        },
        async EZSynSgms(data) {
            const body = {
                BookingDetailId: data
            }
            await starter.api_BG_RBKD01A(body).then( res => {
                this.showResToast(res)
                if (res.Status == '200') {
                    this.searchBy(this.DefaultSearch)
                    var index = this.listBooking.findIndex(b => b.BDC == res.Data.BDC);
                    if(index != null){
                    const datagird =this.$refs.dataGrid.instance;
                    if(res.Data.TeeOff){
                        datagird.cellValue(index, "EntryTime", res.Data.TeeOff);
                    }
                    if(res.Data.Finish){
                        datagird.cellValue(index, "ExitTime", res.Data.Finish);
                    }
                    if(res.Data.BuggyCode){
                        datagird.cellValue(index, "BuggyCode", res.Data.BuggyCode);
                    }
                    if(res.Data.CaddyCode){
                        datagird.cellValue(index, "CaddyCode", res.Data.CaddyCode);
                    }
                    if(res.Data.SgmsEZSynchronized){
                        datagird.cellValue(index, "SgmsEZSynchronized", res.Data.SgmsEZSynchronized);
                    }
                    if(res.Data.SgmsEZSynDate){
                        datagird.cellValue(index, "SgmsEZSynDate", res.Data.SgmsEZSynDate);
                    }
                    this.listBooking[index].SgmsEZSynchronized=res.Data.SgmsEZSynchronized;
                    this.listBooking[index].SgmsEZSynDate=res.Data.SgmsEZSynDate;
            }
        }
            })
        },
        onTriggeredEventHandler(payload) {
            if (payload.evt.ctrlKey && payload.key === F) {
                if (this.DefaultSearch == 'BDC') {
                    this.$refs.listBDC.$refs.search.focus()
                }
                if (this.DefaultSearch == 'BAGTAG') {
                    this.$refs.listBagtag.$refs.search.focus()
                }
            }
            if (payload.evt.ctrlKey && payload.key === S) {
                if (this.DefaultSearch == 'BDC') {
                    this.$refs.listBDC.$refs.search.focus()
                }
                if (this.DefaultSearch == 'BAGTAG') {
                    this.$refs.listBagtag.$refs.search.focus()
                }
                setTimeout(() => {
                    this.dataGridInstance.saveEditData()
                }, 500)
            }
            if (payload.evt.ctrlKey && payload.key === Z) {
                this.dataGridInstance.cancelEditData()
            }
            if (payload.evt.ctrlKey && payload.key === G) {
                this.createGroup()
            }
            if (payload.key === F2) {
                this.actionOnSourseAll(true)
            }
            if (payload.key === F3) {
                this.actionOnSourseAll(false)
            }
            if (payload.key === F4) {
                this.viewDetail()
            }
            if (payload.key === F5) {
                this.searchBy(this.DefaultSearch)
            }
        },
        cssRowStarter(e) {
            if (this.ColorFillColumnBDC != 1) {
                if (e.rowType !== 'data') { return }
                if (e.data.BookingDetailStatus === 'CHECK_IN' && !e.data.EntryTime && !e.data.ExitTime) {
                    e.rowElement.style.backgroundColor = this.colorRow.CheckIn
                } else if (e.data.BookingDetailStatus === 'CHECK_IN' && e.data.EntryTime && !e.data.ExitTime) {
                    e.rowElement.style.backgroundColor = this.colorRow.TeeOff
                } else if (e.data.ExitTime && e.data.BookingDetailStatus !== 'CHECK_OUT') {
                    e.rowElement.style.backgroundColor = this.colorRow.Finish
                } else if (e.data.BookingDetailStatus === 'CHECK_OUT') {
                    e.rowElement.style.backgroundColor = this.colorRow.CheckOut
                }
            }

        },
        findColorColumn(data) {
            var color = null
            if (data.BookingDetailStatus === 'CHECK_IN' && !data.EntryTime && !data.ExitTime) {
                color = this.colorRow.CheckIn
            } else if (data.BookingDetailStatus === 'CHECK_IN' && data.EntryTime && !data.ExitTime) {
                color = this.colorRow.TeeOff
            } else if (data.ExitTime && data.BookingDetailStatus !== 'CHECK_OUT') {
                color = this.colorRow.Finish
            } else if (data.BookingDetailStatus === 'CHECK_OUT') {
                color = this.colorRow.CheckOut
            }
            return `background-color: ${color}`
        },
        viewDetail() {
            this.getSelectedData()
            if (this.selectedRowsData.length === 1) {
                this.call_api_RBKSA2(this.selectedRowsData[0])
            } else if (this.selectedRowsData.length === 0) {
                this.showToast('error', this.$t('golf_starter_please_select_member'))
            } else {
                this.showToast('error', this.$t('golf_starter_please_select_one_member'))
            }
        },
        saveGridInstance(e) {
            this.dataGridInstance = e.component
        },
        changeAllDuplicateCaddy(data) {
            if (data) {
                this.listBooking.forEach(x => {
                    x.IsCheckDuplicateCaddy = 1
                })
            } else {
                this.listBooking.forEach(x => {
                    x.IsCheckDuplicateCaddy = null
                })
            }

        },
        onKeyDown(e) {
            if (e.event.ctrlKey && e.event.keyCode === F) {
                e.event.preventDefault();
                if (this.DefaultSearch == 'BDC') {
                    this.$refs.listBDC.$refs.search.focus()
                }
                if (this.DefaultSearch == 'BAGTAG') {
                    this.$refs.listBagtag.$refs.search.focus()
                }
            }
            if (e.event.ctrlKey && e.event.keyCode === S) {
                e.event.preventDefault();
                if (this.DefaultSearch == 'BDC') {
                    this.$refs.listBDC.$refs.search.focus()
                }
                if (this.DefaultSearch == 'BAGTAG') {
                    this.$refs.listBagtag.$refs.search.focus()
                }
                setTimeout(() => {
                    this.dataGridInstance.saveEditData()
                }, 500)
            }
            if (e.event.ctrlKey && e.event.keyCode === Z) {
                e.event.preventDefault();
                this.dataGridInstance.cancelEditData()
            }
            if (e.event.ctrlKey && e.event.keyCode === G) {
                e.event.preventDefault();
                this.createGroup()
            }
            if (e.event.keyCode === F2) {
                e.event.preventDefault();
                this.actionOnSourseAll(true)
            }
            if (e.event.keyCode === F3) {
                e.event.preventDefault();
                this.actionOnSourseAll(false)
            }
            if (e.event.keyCode === F4) {
                e.event.preventDefault();
                this.viewDetail()
            }
            if (e.event.keyCode === F5) {
                e.event.preventDefault();
                this.searchBy(this.DefaultSearch)
            }
        }
    },
}